import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  ThemeProvider,
  CssBaseline,
  Container,
  AppBar,
  Button,
  Toolbar,
} from '@mui/material';
import { theme } from '../App/App';
import QuestionManager from './QuestionManager';
import QuestionTagManager from './QuestionTagManager';
import TestManager from './TestManager';
import TestResultManager from './TestResultManager';
import { isUserLoggedIn, doLogout } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import AdminPolicy from '../../components/AdminPolicy';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AdminPanel: React.FC = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            Sailing Manager Exam
          </Typography>
          <Button color="inherit" onClick={() => navigate("/user/home")}>
            User Page
          </Button>
          <Button color="inherit" onClick={() => navigate("/user/profile")}>
            My Profile
          </Button>
          {isUserLoggedIn() && (
            <Button color="inherit" onClick={() => doLogout(() => navigate("/"))}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box sx={{ width: '100%', typography: 'body1', mt: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Admin Panel
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="admin tabs">
              <Tab label="Subjects" />
              <Tab label="Questions" />
              <Tab label="Tests" />
              <Tab label="Test Results" />
              <Tab label="User Policy" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <QuestionTagManager />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <QuestionManager />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TestManager />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TestResultManager />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <AdminPolicy />
          </TabPanel>

        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AdminPanel;