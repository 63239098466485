import React, { useState } from "react";
import {
    AppBar, Button, Card, CardContent, Container, Dialog, DialogActions,
    Grid, Toolbar, Typography, Box, Paper
} from "@mui/material";
import LoginForm from "../Login";
import SignupForm from "../Signup";
import { useNavigate } from "react-router-dom";
import { doLogout, isUserLoggedIn } from "../../services/auth";
import { theme } from "../App/App";

export const LandingPage = () => {
    const [loginOpen, setLoginOpen] = useState(false);
    const [signupOpen, setSignupOpen] = useState(false);
    const navigate = useNavigate();

    const handleLoginClick = () => setLoginOpen(true);
    const handleClose = () => setLoginOpen(false);
    const handleSignupClick = () => setSignupOpen(true);
    const handleSignupClose = () => setSignupOpen(false);

    const placeholderImages = [
        "/ai.png",
        "/ai.png",
        "/ai.png",
    ];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Sailing Manager Exam
                    </Typography>
                    {isUserLoggedIn() ? (
                        <>
                            <Button
                                color="inherit"
                                onClick={() => doLogout(() => navigate("/"))}
                            >
                                Logout
                            </Button>
                            <Button
                                color="inherit"
                                variant="outlined"
                                onClick={() => navigate("/user/home")}
                                sx={{ ml: 2 }}
                            >
                                Dashboard
                            </Button>
                            <Button
                                color="inherit"
                                variant="outlined"
                                onClick={() => navigate("/user/admin")}
                                sx={{ ml: 2 }}
                            >
                                Admin Panel
                            </Button>
                            <Button
                                color="inherit"
                                variant="outlined"
                                onClick={() => navigate("/user/policy")}
                                sx={{ ml: 2 }}
                            >
                                Admin policy
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button color="inherit" onClick={handleLoginClick}>
                                Login
                            </Button>
                            <Button
                                color="inherit"
                                variant="outlined"
                                sx={{ ml: 2 }}
                                onClick={handleSignupClick}
                            >
                                Sign Up
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Welcome to Sailing Manager Exam
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="textSecondary" paragraph>
                        An AI-powered platform for efficient and comprehensive sailing examinations.
                    </Typography>
                </Paper>

                <Grid container spacing={4}>
                    {placeholderImages.map((img, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Card>
                                <CardContent>
                                    <img src={img} alt={`Exam App Feature ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                                    <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                                        Feature {index + 1}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" align="center">
                                        Description of the exam app feature.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ mt: 4, mb: 2 }}>
                    <Typography variant="h5" align="center" gutterBottom>
                        Why Choose Our Exam Platform?
                    </Typography>
                    <Typography variant="body1" align="center" paragraph>
                        Our AI-powered sailing exam platform offers comprehensive assessments,
                        real-time feedback, and adaptive learning to ensure you're fully prepared
                        for your sailing certification.
                    </Typography>
                </Box>
            </Container>

            <footer style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, padding: '1rem', marginTop: '2rem' }}>
                <Typography variant="body2" align="center">
                    © {new Date().getFullYear()} Sailing Manager Exam. All rights reserved.
                </Typography>
            </footer>

            {/* Login Dialog */}
            <Dialog open={loginOpen} onClose={handleClose}>
                <LoginForm />
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Signup Dialog */}
            <Dialog open={signupOpen} onClose={handleSignupClose}>
                <SignupForm />
                <DialogActions>
                    <Button onClick={handleSignupClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};