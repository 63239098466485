import { makeAutoObservable } from "mobx";

class AuthStore {
  // this is needed by api-client intercpetor to add token with every api call
  token = "";
  username = "";

  constructor() {
    makeAutoObservable(this);
    this.loadToken();
    this.loadUsername();
  }

  setToken(token: string) {
    this.token = token;
    localStorage.setItem("token", token); // Store token in localStorage
  }

  setUsername(username: string) {
    this.username = username;
    localStorage.setItem("username", username); // Store token in localStorage
  }

  loadToken() {
    const token = localStorage.getItem("token");
    if (token) {
      this.token = token;
      return token;
    }
    return null;
  }

  loadUsername() {
    const username = localStorage.getItem("username");
    if (username) {
      this.username = username;
      return username;
    }
    return null;
  }

  clearToken() {
    this.token = "";
    localStorage.removeItem("token"); // Remove token from localStorage
  }

  clearUsername() {
    this.username = "";
    localStorage.removeItem("username"); // Remove token from localStorage
  }
}

const authStore = new AuthStore();
export default authStore;
