import authStore from "../../store/authStore";
import api from "../api-client";
import { UserUpdateRequest } from "../../dto/UserUpdateRequest";
import { Test } from "../../dto/Test";
import { TopicDTO } from "../../dto/TopicDTO";

export const logIn = async (user: any) => {
  const response = await api.post("/na-auth/login", user);
  return response.data;
};

export const signUp = async (user: any) => {
  const response = await api.post("/na-auth/signup", user);
  return response.data;
};

export const isUserLoggedIn = () => {
  const data = authStore.loadToken();
  if (data) return true;
  else return false;
};

export const doLogin = (data: any, next: any) => {
  authStore.setToken(data.jwtToken);
  authStore.setUsername(data.username);
  next();
};

export const doLogout = (next: any) => {
  authStore.clearToken();
  authStore.clearUsername();
  next();
};

export const getCurrentUser = () => {
  if (isUserLoggedIn()) {
    const userData = localStorage.getItem("data");
    if (userData) {
      return JSON.parse(userData).user;
    }
  }
  return undefined;
};

// user profile management

export const getUserProfile = async (): Promise<any> => {
  const response = await api.get(`user`);
  return response.data.data;
};

export const updateUserProfile = async (UserUpdateRequest: UserUpdateRequest): Promise<any> => {
  const response = await api.put(`user`, UserUpdateRequest);
  return response.data.data;
};


// Previous test result

export const getPreviousTests = async (): Promise<Test[]> => {
  const response = await api.get(`test/result`);
  return response.data;
};

// Available test

export const getAvailableTests = async (): Promise<TopicDTO[]> => {
  const response = await api.get(`topic/with-subtopics`);
  return response.data;
};