import axios from "axios";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Router for navigation
import authStore from "../../store/authStore";
import { getTenantName } from "../../utils/url";

const api = axios.create({
  baseURL: "/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the JWT token in each request
api.interceptors.request.use(
  (config) => {
    const token = authStore.token; // Get the JWT token from your auth store
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Include token in Authorization header
    }
    config.headers.tenant = getTenantName();; // Include tenant in the headers
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token expired or authentication failed
      authStore.clearToken(); // Clear the expired token from the store
      authStore.clearUsername();
      // Redirect to the login page
      const navigate = useNavigate();
      navigate("/login");
    }
    return Promise.reject(error);
  }
);

export default api;
