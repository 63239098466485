/* Get the subdomain from the current URL, subdomain is tenant name.
 *
 * Example:
 * If the current URL is "https://dev.stockmarketstack.com/dashboard", the function will return "dev".
 *
 * Note: This function assumes that the subdomain is always present in the URL.
 * 
 * for www.stockmarketstack.com, return "demo"
 *
*/
export const getSubdomain = (): string => {
  const currentUrl = getCurrentUrl();
  const match = currentUrl.match(/^(?:https?:\/\/)?([^/]+)\./);
  
  // If the URL is an IP address or doesn't have a valid subdomain, return "dev"
  const isIPAddress = currentUrl.match(/^(?:https?:\/\/)?(\d{1,3}\.){3}\d{1,3}(:\d+)?/);
  
  return isIPAddress || !match ? "dev" : match[1];
};

export const getCurrentUrl = (): string => {
  return window.location.href;
};

export const getTenantName = (): string => getSubdomain();
