// AppStore.ts
import { makeAutoObservable } from "mobx";

class AppStore {

  constructor() {
    makeAutoObservable(this);
  }
}

export const appStore = new AppStore();
