import { createTheme, ThemeOptions } from '@mui/material/styles';

const baseTheme: ThemeOptions = {
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
};

export const themeMap: { [key: string]: ReturnType<typeof createTheme> } = {
  dev: createTheme({
    ...baseTheme,
    palette: {
      primary: {
        main: '#1976d2',
        light: '#42a5f5',
        dark: '#1565c0',
      },
      secondary: {
        main: '#388e3c',
        light: '#4caf50',
        dark: '#2e7d32',
      },
      background: {
        default: '#fafafa',
      },
    },
  }),
  stage: createTheme({
    ...baseTheme,
    palette: {
      primary: {
        main: '#0d47a1',
        light: '#1976d2',
        dark: '#002171',
      },
      secondary: {
        main: '#1b5e20',
        light: '#4caf50',
        dark: '#003300',
      },
      background: {
        default: '#e8eaf6',
      },
    },
  }),
  prod: createTheme({
    ...baseTheme,
    palette: {
      primary: {
        main: '#303f9f',
        light: '#3f51b5',
        dark: '#1a237e',
      },
      secondary: {
        main: '#00695c',
        light: '#009688',
        dark: '#004d40',
      },
      background: {
        default: '#eceff1',
      },
    },
  }),
};