import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography, Button, Container, List, ListItem, ListItemText, Box, AppBar, Toolbar,
  Paper, Grid, ThemeProvider, CssBaseline, Chip
} from '@mui/material';
import { doLogout, isUserLoggedIn } from '../../services/auth';
import api from '../../services/api-client';
import { theme } from '../App/App';
import TestResultManager from '../AdminPanel/TestResultManager';

interface TestAttempt {
  id: number;
  testName: string;
  score: number;
  startTime: Date;
  endTime: Date;
  status: string;
}

interface AvailableTest {
  id: number;
  name: string;
  tagIds: number[];
}

interface Tag {
  id: number;
  name: string;
}

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [testAttempts, setTestAttempts] = useState<TestAttempt[]>([]);
  const [availableTests, setAvailableTests] = useState<AvailableTest[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  useEffect(() => {
    fetchTestAttempts();
    fetchAvailableTests();
    fetchTags();
  }, []);

  const fetchTestAttempts = async () => {
    try {
      const response = await api.get('testResult');
      setTestAttempts(response.data.slice(0, 5)); // Get only the last 5 attempts
    } catch (error) {
      console.error('Failed to fetch test attempts', error);
    }
  };

  const fetchAvailableTests = async () => {
    try {
      const response = await api.get('test');
      setAvailableTests(response.data);
    } catch (error) {
      console.error('Failed to fetch available tests', error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await api.get('questionTag');
      setTags(response.data);
    } catch (error) {
      console.error('Failed to fetch tags', error);
    }
  };

  const handleStartTest = (testId: number) => {
    navigate(`/user/test/${testId}`);
  };

  const handleFeedback = (testId: number) => {
    navigate(`/user/feedback/${testId}`);
  };

  const getTagName = (tagId: number) => {
    const tag = tags.find(t => t.id === tagId);
    return tag ? tag.name : 'Unknown Tag';
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static" color="primary" elevation={0}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
              Sailing Manager Exam
            </Typography>
            <Button color="inherit" onClick={() => navigate("/user/admin")}>
              Admin Page
            </Button>
            <Button color="inherit" onClick={() => navigate("/user/profile")}>
              My Profile
            </Button>
            {isUserLoggedIn() && (
              <Button color="inherit" onClick={() => doLogout(() => navigate("/"))}>
                Logout
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Container maxWidth="md" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
            Welcome to Your Dashboard
          </Typography>

          <Paper elevation={3} sx={{ mb: 4, overflow: 'hidden' }}>
            <Box sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              py: 2,
              px: 3,
            }}>
              <Typography variant="h6">Last 5 Test Attempts</Typography>
            </Box>
            <Box sx={{ p: 0 }}>
              <TestResultManager hideUsername={true} limit={5} />
            </Box>
          </Paper>

          <Paper elevation={3} sx={{ mb: 4, overflow: 'hidden' }}>
            <Box sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              py: 2,
              px: 3,
            }}>
              <Typography variant="h6">Available Tests</Typography>
            </Box>
            <Box sx={{ p: 3 }}>
              <Grid container spacing={2}>
                {availableTests.map((test) => (
                  <Grid item xs={12} key={test.id}>
                    <Paper elevation={1} sx={{
                      p: 2,
                      backgroundColor: theme.palette.grey[200],
                    }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6">{test.name}</Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleStartTest(test.id)}
                        >
                          Start Test
                        </Button>
                      </Box>
                      {/* Uncomment this section if you want to display tags */}
                      {/* <Box sx={{ mt: 2 }}>
                        {test.tagIds.map((tagId) => (
                          <Chip key={tagId} label={getTagName(tagId)} sx={{ mr: 1, mb: 1 }} />
                        ))}
                      </Box> */}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Home;