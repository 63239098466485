import React, { Suspense, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isUserLoggedIn } from "../../services/auth";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { themeMap } from "../../config/theme";
import { getTenantName } from "../../utils/url";
import { StoreProvider } from "../../store";
import { LandingPage } from "../Landing";
import Home from "../Home";
import UserProfile from "../../components/UserProfile";
import StartTest from "../StartTest";
import Feedback from "../Feedback";
import AdminPanel from "../AdminPanel";
import AdminPolicy from "../../components/AdminPolicy";

const NotFoundPage = React.lazy(() => import("../NotFound"));

const tenant = getTenantName();
export const theme = createTheme(themeMap[tenant]);  // Theme set based on tenant preference.

// PrivateRoute component to handle authentication checks
const PrivateRoute = () => {
  return isUserLoggedIn() ? <Outlet /> : <Navigate to="/" />;
};

const App: React.FC = () => {

  const [selectedSubTopics, setSelectedSubTopics] = useState<number[]>([]);

  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <ToastContainer />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              {/* Private route will be placed below */}
              <Route path="/user" element={<PrivateRoute />}>
                <Route path="home" element={<Home />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="test/:testId" element={<StartTest />} />
                <Route path="feedback/:testId" element={<Feedback />} />
                <Route path="admin" element={<AdminPanel/>} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        </Router>
      </ThemeProvider>
    </StoreProvider>
  );
};

export default App;
