import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import api from '../../services/api-client';

interface Test {
  id: number;
  name: string;
  tagIds: number[];
}

interface QuestionTag {
  id: number;
  name: string;
}

const TestManager: React.FC = () => {
  const [tests, setTests] = useState<Test[]>([]);
  const [tags, setTags] = useState<QuestionTag[]>([]);
  const [newTest, setNewTest] = useState<Test>({ id: 0, name: '', tagIds: [] });
  const [editTest, setEditTest] = useState<Test | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchTests();
    fetchTags();
  }, []);

  const fetchTests = async () => {
    try {
      const response = await api.get('test');
      setTests(response.data);
    } catch (error) {
      console.error('Error fetching tests:', error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await api.get('questionTag');
      setTags(response.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleAddTest = async () => {
    try {
      await api.post('test', newTest);
      setNewTest({ id: 0, name: '', tagIds: [] });
      fetchTests();
    } catch (error) {
      console.error('Error adding test:', error);
    }
  };

  const handleEditTest = (test: Test) => {
    setEditTest(test);
    setOpenDialog(true);
  };

  const handleUpdateTest = async () => {
    if (editTest) {
      try {
        await api.put(`test/${editTest.id}`, editTest);
        setOpenDialog(false);
        fetchTests();
      } catch (error) {
        console.error('Error updating test:', error);
      }
    }
  };

  const handleDeleteTest = async (id: number) => {
    try {
      await api.delete(`test/${id}`);
      fetchTests();
    } catch (error) {
      console.error('Error deleting test:', error);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
        <TextField
          label="Test Name"
          value={newTest.name}
          onChange={(e) => setNewTest({ ...newTest, name: e.target.value })}
          sx={{ mb: 2 }}
        />
        <FormControl sx={{ mb: 2 }}>
          <InputLabel>Tags</InputLabel>
          <Select
            multiple
            value={newTest.tagIds}
            onChange={(e) => setNewTest({ ...newTest, tagIds: e.target.value as number[] })}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {(selected as number[]).map((value) => (
                  <Chip key={value} label={tags.find(tag => tag.id === value)?.name} />
                ))}
              </Box>
            )}
          >
            {tags.map((tag) => (
              <MenuItem key={tag.id} value={tag.id}>
                {tag.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleAddTest}>
          Add Test
        </Button>
      </Box>
      <List>
        {tests.map((test) => (
          <ListItem
            key={test.id}
            secondaryAction={
              <>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEditTest(test)}>
                  <Edit />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTest(test.id)}>
                  <Delete />
                </IconButton>
              </>
            }
          >
            <ListItemText
              primary={test.name}
              secondary={`Tags: ${test.tagIds.map(id => tags.find(tag => tag.id === id)?.name).join(', ')}`}
            />
          </ListItem>
        ))}
      </List>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Test</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Test Name"
            fullWidth
            value={editTest?.name || ''}
            onChange={(e) => setEditTest(editTest ? { ...editTest, name: e.target.value } : null)}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Tags</InputLabel>
            <Select
              multiple
              value={editTest?.tagIds || []}
              onChange={(e) => setEditTest(editTest ? { ...editTest, tagIds: e.target.value as number[] } : null)}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {(selected as number[]).map((value) => (
                    <Chip key={value} label={tags.find(tag => tag.id === value)?.name} />
                  ))}
                </Box>
              )}
            >
              {tags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleUpdateTest}>Update</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TestManager;