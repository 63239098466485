import React, { useState, useEffect, useRef } from 'react';
import {
  Typography, Button, Container, TextField, Box, AppBar, Toolbar,
  Paper, Stepper, Step, StepLabel, CircularProgress, ThemeProvider, CssBaseline,
  Alert, Snackbar
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api-client';
import { isUserLoggedIn, doLogout } from '../../services/auth';
import { theme } from '../App/App';

interface Question {
  id: number;
  questionText: string;
  tagIds: number[];
  question: string;
}

interface Tag {
  id: number;
  name: string;
}

const StartTest: React.FC = () => {
  const navigate = useNavigate();
  const { testId } = useParams<{ testId: string }>();
  const [testResultId, setTestResultId] = useState<number | null>(null);
  const [tags, setTags] = useState<Tag[]>([]);
  const [currentTagIndex, setCurrentTagIndex] = useState(0);
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [loading, setLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarColor, setSnackbarColor] = useState<'info' | 'success' | 'error'>('info');
  const [tagToQuestionIds, setTagToQuestionIds] = useState<{ [tagId: number]: number[] }>({});
  const [fetchedQuestions, setFetchedQuestions] = useState<{ [questionId: number]: Question }>({});
  const [isInitialized, setIsInitialized] = useState(false);
  const initializationRef = useRef(false);

  useEffect(() => {
    const initializeTest = async () => {
      if (initializationRef.current) return; // Skip if already initialized
      initializationRef.current = true;

      try {
        setLoading(true);

        // Create a test result
        const testResultResponse = await api.post('testResult', { testId });
        setTestResultId(testResultResponse.data.id);

        // Fetch test details
        const testResponse = await api.get(`test/${testId}`);
        const testTagIds = testResponse.data.tagIds;

        // Fetch only the tags associated with the test
        const tagResponse = await api.get(`questionTag`);
        const allTags = tagResponse.data;
        const testTags = allTags.filter((tag: Tag) => testTagIds.includes(tag.id));
        setTags(testTags);

        // Fetch questions for the test
        const questionsResponse = await api.get(`question`, { params: { testId } });
        const questions = questionsResponse.data;

        // Create tag to question map
        const tagToQuestionMap: { [tagId: number]: number[] } = {};
        const fetchedQuestionsMap: { [id: number]: Question } = {};

        questions.forEach((question: any) => {
          question.tagIds.forEach((tagId: number) => {
            if (!tagToQuestionMap[tagId]) {
              tagToQuestionMap[tagId] = [];
            }
            tagToQuestionMap[tagId].push(question.id);
          });

          fetchedQuestionsMap[question.id] = {
            id: question.id,
            questionText: question.question,
            tagIds: question.tagIds,
            question: question.question,
          };
        });

        setTagToQuestionIds(tagToQuestionMap);
        setFetchedQuestions(fetchedQuestionsMap);

        setIsInitialized(true);
      } catch (error) {
        console.error('Failed to initialize test', error);
        setSnackbarMessage('Failed to initialize test. Please try again.');
        setSnackbarColor('error');
        setShowSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    if (!isInitialized) {
      initializeTest();
    }
  }, [testId, isInitialized]);

  const handleAnswerChange = (questionId: number, answer: string) => {
    setAnswers(prev => ({ ...prev, [questionId]: answer }));
  };

  const handleNext = () => {
    if (currentTagIndex < tags.length - 1) {
      setCurrentTagIndex(prev => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentTagIndex > 0) {
      setCurrentTagIndex(prev => prev - 1);
    }
  };

  const handleSubmit = async () => {
    setShowSnackbar(true);
    setSnackbarMessage('Submitting Test...');
    setSnackbarColor('info');
    try {
      // Prepare the list of SubmittedAnswerDTO objects
      const submittedAnswers = Object.entries(answers).map(([questionId, answer]) => ({
        testResultId,
        questionId: parseInt(questionId),
        answer,
      }));

      // Submit answers using SubmittedAnswerController
      await api.post('submittedAnswer', submittedAnswers);

      // Update test result status using TestResultController
      await api.put(`testResult/${testResultId}`, { status: 'PENDING_EVALUATION' });

      setSnackbarMessage('Test submitted successfully! Redirecting...');
      setSnackbarColor('success');
      setTimeout(() => {
        navigate('/user/home', { state: { testSubmitted: true } });
      }, 2000);
    } catch (error) {
      console.error('Failed to submit test', error);
      setSnackbarMessage('Failed to submit test. Please try again.');
      setSnackbarColor('error');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const currentTag = tags[currentTagIndex];
  const currentTagQuestionIds = currentTag ? tagToQuestionIds[currentTag.id] || [] : [];
  const currentTagQuestions = currentTagQuestionIds
    .map(id => fetchedQuestions[id])
    .filter(q => q !== undefined);

  console.log('Current Tag:', currentTag);
  console.log('Current Tag Questions:', currentTagQuestions);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static" color="primary" elevation={0}>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Test in Progress
            </Typography>
            <Button color="inherit" onClick={doLogout}>Logout</Button>
          </Toolbar>
        </AppBar>
        <Container component="main" maxWidth="md" sx={{ mt: 4, mb: 4 }}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Stepper activeStep={currentTagIndex} alternativeLabel>
              {tags.map((tag, index) => (
                <Step key={tag.id}>
                  <StepLabel>{tag.name}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box sx={{ mt: 4 }}>
              {currentTagQuestions.map((question) => (
                <Box key={question.id} sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    {question.questionText}
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={answers[question.id] || ''}
                    onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                  />
                </Box>
              ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                onClick={handlePrevious}
                disabled={currentTagIndex === 0}
              >
                Previous
              </Button>
              {currentTagIndex === tags.length - 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit Test
                </Button>
              ) : (
                <Button
                  onClick={handleNext}
                  disabled={currentTagIndex === tags.length - 1}
                >
                  Next
                </Button>
              )}
            </Box>
          </Paper>
        </Container>
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity={snackbarColor} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default StartTest;