import React, { useState, useEffect } from 'react';
import {
    Box, Button, Dialog, DialogTitle, DialogContent, DialogActions,
    Accordion, AccordionSummary, AccordionDetails, Grid, TextField, Snackbar, Alert,
    Chip, Typography
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import api from '../../services/api-client';

interface TestResult {
    id: number;
    testName: string;
    username: string;
    score: number;
    status: string;
    startTime: string;
}

interface QuestionAnswerDTO {
    id: number;
    questionText: string;
    submittedAnswer: string;
    evaluationPrompt: string;
    score: number;
    status: string;
    submittedAnswerId: number;
}

interface TestResultDetail extends TestResult {
    attemptTime: String;
    questions: QuestionAnswerDTO[];
}

interface TestResultManagerProps {
    hideUsername?: boolean;
    limit?: number;
}

interface Feedback {
    id?: number;
    questionId: number;
    submittedAnswerId: number;
    feedback: string;
}

const TestResultManager: React.FC<TestResultManagerProps> = ({ hideUsername = false, limit }) => {
    const [testResults, setTestResults] = useState<TestResult[]>([]);
    const [selectedResult, setSelectedResult] = useState<TestResultDetail | null>(null);
    const [feedbacks, setFeedbacks] = useState<{ [key: string]: Feedback }>({});
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' as 'success' | 'error' });

    useEffect(() => {
        // Fetch immediately on mount
        fetchTestResults();

        // Set up interval to fetch every 10 seconds
        const fetchInterval = setInterval(() => {
            fetchTestResults();
        }, 10000); // 10000 milliseconds = 10 seconds

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(fetchInterval);
    }, []);

    const fetchTestResults = async () => {
        setLoading(true);
        try {
            const response = await api.get('testResult');
            let results = response.data;
            if (limit) {
                results = results.slice(0, limit);
            }
            setTestResults(results);
        } catch (error) {
            console.error('Error fetching test results:', error);
            setSnackbar({ open: true, message: 'Failed to fetch test results', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleViewDetails = async (id: number) => {
        setLoading(true);
        try {
            const response = await api.get(`testResult/${id}/details`);
            setSelectedResult(response.data);
            fetchFeedbacks(response.data.questions);
            setOpenDialog(true);
        } catch (error) {
            console.error('Error fetching test result details:', error);
            setSnackbar({ open: true, message: 'Failed to fetch test result details', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const fetchFeedbacks = async (questions: QuestionAnswerDTO[]) => {
        const feedbackPromises = questions.map(question =>
            api.get(`feedback/question/${question.id}/answer/${question.submittedAnswerId}`)
        );

        try {
            const feedbackResponses = await Promise.all(feedbackPromises);
            const newFeedbacks: { [key: string]: Feedback } = {};
            feedbackResponses.forEach((response, index) => {
                const question = questions[index];
                const key = `${question.id}-${question.submittedAnswerId}`;
                newFeedbacks[key] = response.data || {
                    questionId: question.id,
                    submittedAnswerId: question.submittedAnswerId,
                    feedback: ''
                };
            });
            setFeedbacks(newFeedbacks);
        } catch (error) {
            console.error('Error fetching feedbacks:', error);
            setSnackbar({ open: true, message: 'Failed to fetch feedbacks', severity: 'error' });
        }
    };

    const handleFeedbackChange = (questionId: number, submittedAnswerId: number, feedback: string) => {
        const key = `${questionId}-${submittedAnswerId}`;
        setFeedbacks(prev => ({
            ...prev,
            [key]: { ...prev[key], feedback }
        }));
    };

    const handleSubmitFeedback = async () => {
        if (!selectedResult) return;

        setLoading(true);
        try {
            const feedbackPromises = Object.values(feedbacks).map(feedback => {
                if (feedback.id) {
                    return api.put(`feedback/${feedback.id}`, feedback);
                } else {
                    return api.post('feedback', feedback);
                }
            });

            await Promise.all(feedbackPromises);
            setSnackbar({ open: true, message: 'Feedback submitted successfully', severity: 'success' });
            setOpenDialog(false);
            fetchTestResults(); // Refresh the test results
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setSnackbar({ open: true, message: 'Failed to submit feedback', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const columns: GridColDef[] = [
        { field: 'testName', headerName: 'Test Name', flex: 1 },
        ...(hideUsername ? [] : [{ field: 'userId', headerName: 'Username', flex: 1 }]),
        {
            field: 'startTime',
            headerName: 'Attempt Time',
            flex: 1,
            valueFormatter: (params) => params || '',
        },
        { field: 'score', headerName: 'Score', width: 100 },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params: GridRenderCellParams) => (
                <Chip
                    label={params.value}
                    color={params.value === 'RESULT_AVAILABLE' ? 'success' : 'warning'}
                    size="small"
                />
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            renderCell: (params: GridRenderCellParams) => (
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleViewDetails(params.row.id)}
                >
                    View Details
                </Button>
            ),
        },
    ];

    return (
        <Box sx={{
            width: '100%',
            height: 'auto'
        }}>
            <DataGrid
                rows={testResults}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: limit || 15 },
                    },
                }}
                pageSizeOptions={limit ? undefined : [15, 25, 50]}
                loading={loading}
                disableRowSelectionOnClick
                sx={{
                    backgroundColor: 'white',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: '#e0e0e0', // Equivalent to grey.300
                        color: 'text.primary',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                    },
                    '& .MuiDataGrid-cell': {
                        color: 'text.primary',
                        fontSize: '0.875rem',
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'grey.200',
                    },
                }}
            />

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                    }
                }}
            >
                <DialogTitle sx={{
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    py: 2,
                }}>
                    Test Result Details
                </DialogTitle>
                <DialogContent sx={{ py: 3 }}>
                    {selectedResult && (
                        <Box>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6" gutterBottom>
                                        Test: {selectedResult.testName}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Score: <strong>{selectedResult.score}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" gutterBottom>
                                            Status: <Chip
                                                label={selectedResult.status}
                                                color={selectedResult.status === 'RESULT_AVAILABLE' ? 'success' : 'error'}
                                                size="small"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body1" gutterBottom>
                                        Attempt Time: {selectedResult.attemptTime}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                                Questions and Answers
                            </Typography>
                            {selectedResult.questions.map((question, index) => (
                                <Accordion
                                    key={question.id}
                                    sx={{
                                        '&:before': { display: 'none' },
                                        boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
                                        mb: 2,
                                        borderRadius: 1,
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{
                                            bgcolor: 'grey.100',
                                            '&:hover': { bgcolor: 'grey.200' }
                                        }}
                                    >
                                        <Typography><strong>Question {index + 1}:</strong> {question.questionText}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="textSecondary">Submitted Answer:</Typography>
                                                <Typography variant="body1" sx={{ mt: 0.5, mb: 1.5 }}>{question.submittedAnswer}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="textSecondary">Evaluation:</Typography>
                                                <Typography variant="body1" sx={{ mt: 0.5, mb: 1.5 }}>{question.evaluationPrompt}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="textSecondary">Score: {question.score}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" gutterBottom>
                                                    Status: <Chip
                                                        label={question.status}
                                                        color={question.status === 'RESULT_AVAILABLE' ? 'success' : 'error'}
                                                        size="small"
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={1}
                                                    variant="outlined"
                                                    label="Feedback"
                                                    value={feedbacks[`${question.id}-${question.submittedAnswerId}`]?.feedback || ''}
                                                    onChange={(e) => handleFeedbackChange(question.id, question.submittedAnswerId, e.target.value)}
                                                    sx={{ mt: 2 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2, bgcolor: 'grey.100' }}>
                    <Button
                        onClick={handleSubmitFeedback}
                        color="primary"
                        variant="contained"
                        sx={{ mr: 1 }}
                    >
                        Submit Feedback
                    </Button>
                    <Button onClick={() => setOpenDialog(false)} variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default TestResultManager;