import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../services/api-client';

interface Role {
  id: number;
  name: string;
}

interface Policy {
  id: number;
  ptype: string;
  v0: string;
  v1: string;
  v2: string;
}

interface User {
  id: number;
  username: string;
  email: string;
  accountExpired: boolean;
  accountLocked: boolean;
  accountCredentialsExpired: boolean;
  roles: Role[];
  enabled: boolean;
  approved: boolean;
}

const AdminPolicy: React.FC = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [newRole, setNewRole] = useState<string>('');
  const [newPolicy, setNewPolicy] = useState<Omit<Policy, 'id'>>({
    ptype: '',
    v0: '',
    v1: '',
    v2: '',
  });
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
  const [openAssignRoleDialog, setOpenAssignRoleDialog] = useState(false);

  useEffect(() => {
    fetchRoles();
    fetchPolicies();
    fetchUsers();
  }, []);

  const fetchRoles = async () => {
    const response = await api.get<{ data: Role[] }>('admin/roles');
    setRoles(response.data.data);
  };

  const fetchPolicies = async () => {
    const response = await api.get<{ data: Policy[] }>('admin/policies');
    setPolicies(response.data.data);
  };

  const fetchUsers = async () => {
    const response = await api.get<{ data: User[] }>('user/all');
    setUsers(response.data.data);
  };

  const handleCreateRole = async () => {
    await api.post('admin/roles', { name: newRole });
    setNewRole('');
    fetchRoles();
    setOpenRoleDialog(false);
  };

  const handleCreatePolicy = async () => {
    await api.post('admin/policies', newPolicy);
    setNewPolicy({ ptype: '', v0: '', v1: '', v2: '' });
    fetchPolicies();
    setOpenPolicyDialog(false);
  };

  const handleDeletePolicy = async (id: number) => {
    await api.delete(`admin/policies/${id}`);
    fetchPolicies();
  };

  const handleAssignRoles = async () => {
    await api.post(`admin/user/${selectedUser}/roles`, selectedRoles);
    setSelectedUser('');
    setSelectedRoles([]);
    setOpenAssignRoleDialog(false);
    fetchUsers();
  };

  const handleRemoveRole = async (userId: number, roleId: number) => {
    await api.delete(`admin/user/${userId}/roles/${roleId}`);
    fetchUsers();
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'username', headerName: 'Username', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'enabled', headerName: 'Enabled', width: 100, type: 'boolean' },
    { field: 'approved', headerName: 'Approved', width: 100, type: 'boolean' },
    {
      field: 'roles',
      headerName: 'Roles',
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value.map((role: Role) => (
            <Chip
              key={role.id}
              label={role.name}
              size="small"
              onDelete={() => handleRemoveRole(params.row.id, role.id)}
              style={{ margin: '2px' }}
            />
          ))}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 140,
      renderCell: (params) => (
        <Button
          color="primary"
          onClick={() => {
            setSelectedUser(params.row.id.toString());
            setSelectedRoles(params.row.roles.map((role: Role) => role.id));
            setOpenAssignRoleDialog(true);
          }}
        >
          Assign Roles
        </Button>
      ),
    },
  ];

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Admin Panel
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h5" gutterBottom>
              Users
            </Typography>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid rows={users} columns={columns} />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" onClick={() => setOpenRoleDialog(true)}>
            Manage Roles
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" onClick={() => setOpenPolicyDialog(true)}>
            RBAC Policies
          </Button>
        </Grid>
      </Grid>

      {/* Role Management Dialog */}
      <Dialog open={openRoleDialog} onClose={() => setOpenRoleDialog(false)}>
        <DialogTitle>Manage Roles</DialogTitle>
        <DialogContent>
          <TextField
            label="New Role"
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
            fullWidth
            margin="normal"
          />
          <List>
            {roles.map((role) => (
              <ListItem key={role.id}>
                <ListItemText primary={role.name} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRoleDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateRole} variant="contained" color="primary">
            Create Role
          </Button>
        </DialogActions>
      </Dialog>

      {/* Policy Management Dialog */}
      <Dialog open={openPolicyDialog} onClose={() => setOpenPolicyDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Manage RBAC Policies</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                label="PType"
                value={newPolicy.ptype}
                onChange={(e) => setNewPolicy({ ...newPolicy, ptype: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Role"
                value={newPolicy.v0}
                onChange={(e) => setNewPolicy({ ...newPolicy, v0: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Service"
                value={newPolicy.v1}
                onChange={(e) => setNewPolicy({ ...newPolicy, v1: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Read/Write"
                value={newPolicy.v2}
                onChange={(e) => setNewPolicy({ ...newPolicy, v2: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>PType</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Read/Write</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {policies.map((policy) => (
                  <TableRow key={policy.id}>
                    <TableCell>{policy.ptype}</TableCell>
                    <TableCell>{policy.v0}</TableCell>
                    <TableCell>{policy.v1}</TableCell>
                    <TableCell>{policy.v2}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeletePolicy(policy.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPolicyDialog(false)}>Cancel</Button>
          <Button onClick={handleCreatePolicy} variant="contained" color="primary">
            Create Policy
          </Button>
        </DialogActions>
      </Dialog>

      {/* Assign Role Dialog */}
      <Dialog open={openAssignRoleDialog} onClose={() => setOpenAssignRoleDialog(false)}>
        <DialogTitle>Assign Roles</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Roles</InputLabel>
            <Select
              multiple
              value={selectedRoles}
              onChange={(e) => setSelectedRoles(e.target.value as number[])}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssignRoleDialog(false)}>Cancel</Button>
          <Button onClick={handleAssignRoles} variant="contained" color="primary">
            Assign Roles
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminPolicy;