// stores/StoreProvider.tsx
import React, { useEffect, useState } from "react";
import { appStore } from "./AppStore";

export const storeContext = React.createContext(appStore);

type Props = {
  children: React.ReactNode;
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
  const [loadingProgress, setLoadingProgress] = useState(0); // Tracks the progress
  const [isCompleted, setIsCompleted] = useState(false);
  // When the progress reaches 100%, hide the progress bar
  if (loadingProgress === 100 && isCompleted) {
    return (
      <storeContext.Provider value={appStore}>
        {children}
      </storeContext.Provider>
    );
  }

  return (
    <div>
      <storeContext.Provider value={appStore}>
        {children}
      </storeContext.Provider>
      {/* Progress bar */}
      <div
        style={{
          position: "fixed",
          bottom: "10px",
          right: "10px",
          width: "200px",
          height: "10px",
          backgroundColor: "black", // Black background
          borderRadius: "5px",
          boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
        }}
      >
        <div
          style={{
            width: `${loadingProgress}%`,
            height: "100%",
            backgroundColor: "#4caf50", // Green progress bar
            borderRadius: "5px",
          }}
        />
      </div>

      {/* Progress percentage text */}
      <div
        style={{
          position: "fixed",
          bottom: "30px",
          right: "10px",
          fontSize: "14px",
          color: "#4caf50", // Green font color
          backgroundColor: "black", // Black background
          padding: "5px",
          borderRadius: "5px",
          fontWeight: "bold",
        }}
      >
        {Math.round(loadingProgress)}%
      </div>
    </div>
  );
};

export const useGlobalStore = () => {
  const context = React.useContext(storeContext);
  if (!context) {
    throw new Error("useGlobalStore must be used within a StoreProvider");
  }
  return context;
};
