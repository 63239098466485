import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Typography, Container, Paper, TextField, Button, List, ListItem, ListItemText, Box,
    ThemeProvider, CssBaseline, CircularProgress, Snackbar, Alert
} from '@mui/material';
import { theme } from '../App/App';
import api from '../../services/api-client';
import { FeedbackDTO } from '../../dto/FeedbackDTO';

const Feedback: React.FC = () => {
    const { testId } = useParams<{ testId: string }>();
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState<FeedbackDTO | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState<'info' | 'success' | 'error'>('info');
    useEffect(() => {
        const fetchFeedback = async () => {
            try {
                const response = await api.get<FeedbackDTO>(`feedback/${testId}`);
                setFeedback(response.data);
            } catch (error) {
                console.error('Failed to fetch feedback', error);
            }
        };
        fetchFeedback();
    }, [testId]);

    const handleFeedbackChange = (questionId: number, newFeedback: string) => {
        if (feedback) {
            const updatedQuestions = feedback.questions.map(q =>
                q.id === questionId ? { ...q, feedback: newFeedback } : q
            );
            setFeedback({ ...feedback, questions: updatedQuestions });
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setShowSnackbar(true);
        setSnackbarMessage('Submitting feedback...');
        setSnackbarColor('info');

        try {
            await api.post('feedback', feedback);
            setSnackbarMessage('Feedback submitted successfully! Redirecting...');
            setSnackbarColor('success');
            setTimeout(() => {
                navigate('/user/home');
            }, 1000);
        } catch (error) {
            console.error('Failed to submit feedback', error);
            setSnackbarMessage('Failed to submit feedback. Please try again.');
            setSnackbarColor('error');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!feedback) {
        return (
            <Container maxWidth="md" sx={{ mt: 4, mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
                    <Typography variant="h4" gutterBottom>Feedback for Test {testId}</Typography>
                    <List>
                        {feedback.questions.map((question) => (
                            <ListItem key={question.id} sx={{ mb: 3, flexDirection: 'column', alignItems: 'flex-start' }}>
                                <ListItemText
                                    primary={<Typography variant="h6">{question.questionText}</Typography>}
                                    secondary={
                                        <Box mt={1}>
                                            <Typography variant="body2" color="text.secondary">
                                                Your Answer: {question.userAnswer}
                                            </Typography>
                                        </Box>
                                    }
                                />
                                <TextField
                                    fullWidth
                                    label="Feedback"
                                    value={question.feedback}
                                    onChange={(e) => handleFeedbackChange(question.id, e.target.value)}
                                    margin="normal"
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Box mt={4} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            size="large"
                            sx={{ minWidth: 200 }}
                        >
                            {isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Submit Feedback'}
                        </Button>
                    </Box>
                </Paper>
            </Container>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setShowSnackbar(false)}
                    severity={snackbarColor}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default Feedback;