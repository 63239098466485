import React, { useEffect, useState } from 'react';
import { User } from '../dto/User';
import { getUserProfile, updateUserProfile } from '../services/auth';
import { toast } from 'react-toastify';
import { UserUpdateRequest } from '../dto/UserUpdateRequest';


// for logged in user. allow password update.

const UserProfile: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                setLoading(true);
                const response = await getUserProfile();
                setUser(response);
            } catch (error) {
                setError('Failed to fetch user profile');
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, []);

    const handlePasswordChange = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (password !== passwordConfirm) {
            setError('Passwords do not match');
            return;
        }

        setError('');

        const userUpdateRequest: UserUpdateRequest = { password };

        const updateUser = async () => {
            try {
                setLoading(true);
                await updateUserProfile(userUpdateRequest);
                toast.success('Password updated successfully');
            } catch (error) {
                setError('Failed to update password');
            } finally {
                setLoading(false);
            }
        };

        updateUser();
    };


    if (loading) return <div>Loading...</div>;
    if (!user) return <div>User not found</div>;
    if (error) return <div>{error}</div>;
    return (
        <div>
            <h2>User Profile</h2>
            <div>
                <label>
                    Name: {user.username}
                </label>
                <label>
                    Email: {user.username}
                </label>
            </div>
            <h2>Change Password</h2>
            <form onSubmit={handlePasswordChange}>
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </label>
                <label>
                    Confirm Password:
                    <input
                        type="password"
                        value={passwordConfirm}
                        onChange={(event) => setPasswordConfirm(event.target.value)}
                    />
                </label>
                <button type="submit">Update Password</button>
                {error && <div>{error}</div>}
            </form>
        </div>
    );
};

export default UserProfile;